<template>
  <ReportContainer class="d-print-block report-payment-print">
    <div class="d-flex justify-space-between pr-3 pl-3 mb-1">
      <ClinicDetail flexDirection="column" style="width: 50%">
        <span class="normal__case w-100">
          <b class="bold__case" v-html="isVnLanguage ? 'PK: ' : 'Clinic: '"></b>
          {{ clinic.clinicName }}
        </span>
        <span class="normal__case w-100">
          <b class="bold__case" v-html="isVnLanguage ? 'ĐC: ' : 'Addr: '"></b>
          {{ clinic.address }}
        </span>
        <span class="normal__case w-100">
          <b class="bold__case" v-html="isVnLanguage ? 'ĐT: ' : 'Phone: '"></b>
          {{ clinic.hotline }}
        </span>
      </ClinicDetail>
      <ImageLogoBox>
        <img
          class="imageLogoBox_Image"
          src="../../../../assets/CT_logo.png"
          alt="Logo Cao thang"
          style="width: auto; height: 60px"
        />
      </ImageLogoBox>
    </div>
    <TitlePrescription>
      <span v-html="`${$t('Print.VisitStatistics')}` + dateRangeString"></span>
    </TitlePrescription>
    <PaymentTable ref="PaymentTable" />
  </ReportContainer>
</template>

<script>
import BrandService from "@/services/brand";
import {
  ReportContainer,
  TitlePrescription,
  ClinicDetail,
  ImageLogoBox,
} from "@/components/commonComponents/Payment/css/style";
import PaymentTable from "./PaymentTable.vue";
import moment from "moment";
export default {
  components: {
    ReportContainer,
    TitlePrescription,
    ClinicDetail,
    ImageLogoBox,
    PaymentTable,
  },
  data() {
    return {
      dateRangeString: "",
      clinic: {},
      isVnLanguage: true,
    };
  },
  mounted() {
    this.print();
  },
  methods: {
    async print() {
      var clinicID = parseInt(this.$route.query.clinicID);
      var startTime = this.$route.query.startTime;
      var endTime = this.$route.query.endTime;
      this.renderDateRangeString();
      if (!clinicID || !startTime || !endTime) return;
      await this.renderClinicDetail(clinicID);
      await this.$refs.PaymentTable.renderPaymentList(
        [startTime, endTime],
        clinicID
      );
      window.print();
    },
    async renderClinicDetail(clinicID) {
      var result = await BrandService.getBrandClinicByID(clinicID);
      if (!result || result.error) {
        return;
      }
      this.clinic = result;
    },
    renderDateRangeString() {
      var start = moment(this.$route.query.startTime).format("DD/MM/YYYY");
      var end = moment(this.$route.query.endTime).format("DD/MM/YYYY");
      if (start == end) {
        this.dateRangeString = start;
        return;
      }
      this.dateRangeString = `${start} - ${end}`;
    },
  },
};
</script>
<style lang="scss">
.report-payment-print {
  table {
    border-top: 1px solid #bdbdbd !important;
    border-right: 1px solid #bdbdbd !important;
    th {
      border-left: 1px solid #bdbdbd !important;
      border-bottom: 1px solid #bdbdbd !important;
    }
    td {
      border-left: 1px solid #bdbdbd !important;
      border-bottom: 1px solid #bdbdbd !important;
    }
  }
}
@media print {
  * {
    margin: 0px;
    padding: 0px;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 9px !important;
  }
  .title {
    font-size: 24px !important;
  }
  .title-2 {
    font-size: 18px !important;
    font-weight: bold;
  }
  .sub {
    font-size: 10px;
  }
  .mp-0 {
    margin: 0px;
    padding: 0px;
  }
  .text-right {
    padding-right: 10px;
  }
  .text-left {
    width: 100%;
    display: inline-block;
    border-bottom: 1px dotted #bdbdbd;
    padding-bottom: 2px;
  }
  .mw-120 {
    min-width: 120px;
    max-width: 160px;
  }
}
</style>