import styled from 'vue-styled-components';
export const Container = styled.div`
    justify-content: center !important;
    display: flex;
   
    @media print {
        * {
            font-size: 10px;
            font-family: Arial, Helvetica, sans-serif !important;
        }
        @page {
            size: auto;
            size: portrait;
            margin: 2%;
        }
    }
`;

export const ReportContainer = styled.div`
    justify-content: center !important;
    display: flex;
   
    @media print {
        * {
            font-size: 8px;
            font-family: Arial, Helvetica, sans-serif !important;
        }
        @page {
            size: auto;
            size: landscape;
            margin: 1%;
        }
    }
`;

export const BrandDescription = styled.div`
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    margin: 5px;

    @media print {
        font-size: 12px;
        margin: 2px;
    }
`;

export const BrandName = styled.div`
    text-transform: uppercase;
    text-align: center;
    @media print {
        font-weight: bold;
        font-size: 12px;
        margin: 3px 0;
    }
`;

export const TitlePrescription = styled.div`
    text-align: center;
    text-transform: uppercase;
   
    font-weight: bold;
    margin: 3px 0;

    span {
        font-size: 25px;
    }

    @media only print and (max-width: 298px) and (min-width: 150px) { /* A5 size */
        span {
            font-size: 13px;
        }
    }
    @media only print and (max-width: 420px) and (min-width: 350px) { /* A6 size */
        span {
            font-size: 20px;
        }
    }
    @media only print and (max-width: 1123px) and (min-width: 550px) { /* A4 size */
        span {
            font-size: 24px;
        }
    }
`;

export const ClinicDetail = styled('div', { flexDirection: String })`
    display: flex;
    align-items: center;
    
    flex-direction: ${props => props.flexDirection};
    font-size: 15px;

    .normal__case {
        font-weight: normal;
    }
    .bold__case {
        font-size: 15px;
    }

    @media only print and (max-width: 298px) and (min-width: 150px) { /* A5 size */
        .normal__case {
            font-size: 8px;
        }
        .bold__case {
            font-size: 8px;
        }
    }
    @media only print and (max-width: 420px) and (min-width: 350px) { /* A6 size */
        .normal__case {
            font-size: 10px;
        }
        .bold__case {
            font-size: 10px;
        }
    }
    @media only print and (max-width: 1123px) and (min-width: 550px) { /* A4 size */
        .normal__case {
            font-size: 14px;
        }
        .bold__case {
            font-size: 14px;
        }
    }
`;

export const ImageLogoBox = styled.div`
    display: block;
    box-sizing: border-box;
    height: 90px;
    width: 20%;

    .imageLogoBox_Image {
        height:100%;
        width: 100%;
    }

    @media only print and (max-width: 298px) and (min-width: 150px) { /* A5 size */
        height: 33px;
        width: 32%;
    }

    @media only print and (max-width: 420px) and (min-width: 350px) { /* A6 size */
        height: 50px;
        width: 35%;  
    }
    @media only print and (max-width: 1123px) and (min-width: 550px) {
        height: 60px;
        width: 32%;
    }
`;

export const PatientInfo = styled.div`
    display: block;
    width: 100%;

    .patientInfo__title {
        font-weight: bold;
        padding: 0;
        margin: 0;
        text-align: left;
    }
    .patientInfo__content {
        text-align: left; 
        padding: 0;
        margin: 0;
    }
    .patientInfo__item {
        font-size: 14px;

        .upper__case {
            text-transform: uppercase;
            font-weight: bold;
        }
        
        .bold__case {
            font-weight: bold;
            font-size: 16px;
        }
    }

    @media only print and (max-width: 298px) and (min-width: 150px) { /* A6 size */
        .patientInfo__item {
            font-size: 9px !important;
            .upper__case {
                font-size: 9px;
                text-transform: uppercase;
                font-weight: bold;
            }
            .bold__case {
                font-size: 10px;
                font-weight: bold;
            }
        }
    }
    @media only print and (max-width: 420px) and (min-width: 350px) { /* A5 size */
        .patientInfo__item {
            font-size: 11px;
            .upper__case {
                font-size: 11px;
                font-weight: bold;
                text-transform: uppercase;
            }
            .bold__case {
                font-size: 11px;
                font-weight: bold;
            }
        }
    }     
    @media only print and (max-width: 1123px) and (min-width: 550px) {
        .patientInfo__item {
            font-size: 15px;
            .upper__case {
                font-size: 15px;
                font-weight: bold;
                text-transform: uppercase;
            }
            .bold__case {
                font-size: 15px;
                font-weight: bold;
            }
        }
    } 
`;

export const PatientItem = styled('div', { width: String })`
    display: flex;
    width: ${props => (props.width) ? props.width : '100%'};
    align-items: center;
    
    .patientItem__title {
        width: 15%;
        font-weight: bold;
        text-align: left;
    }
    .patientItem__content {
        width: 80%;
    }

    @media print {
        justify-content: space-between !important;

        .patientItem__content {
            width: 75%;
            text-align: left;
            border-bottom: 0.5px dotted #bdbdbd;
            padding-bottom: 2px;
        }
    }
`;


export const DetailPrescription = styled.div`
    display: block;
    width: 100%;
    padding: 2px;
    margin: 3px;
    @media print {
        padding: 2px;
    }
`;

export const FooterPrescription = styled.div`
    margin: 5px;
    display: flex;
    height: auto;
`;

export const TableContent = styled.table`
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
    th, td {
        border: 0.5px solid black;
    }
    thead {
        th {
            font-weight: bold;

            @media only print and (max-width: 298px) and (min-width: 150px) { /* A6 size */
                font-size: 8px;
            }
            @media only print and (max-width: 420px) and (min-width: 350px) {
                font-size: 12px;
            }
            @media only print and (max-width: 1123px) and (min-width: 550px) {
                font-size: 16px;
            }
        }
    }
    tbody {
        tr {
           .title__prescription {
               padding: 5px 15px;
               font-size: 14px;
               text-align: left; 
               font-weight: 420;
               width: 300px;
               span {
                 font-size: 14px;
               }
               @media only print and (max-width: 298px) and (min-width: 150px) { /* A6 size */
                    font-size: 8px;
                    padding: 1px 3px;
                    width: 100px;
                    span {
                      font-size: 9px;
                    }
               }
               @media only print and (max-width: 420px) and (min-width: 350px) { /* A5 size */
                    font-size: 12px;
                    padding: 2px 5px;
                    width: 120px;
                    span {
                      font-size: 12px;
                    }
               }
               @media only print and (max-width: 1123px) and (min-width: 550px) {
                    font-size: 15px;
                    width: 150px;
               }
           }
           .content__prescription {
                span{
                    font-size: 14px;
                    .bold__case {
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
                padding: 4px;

                @media only print and (max-width: 298px) and (min-width: 150px) { /* A6 size */
                    padding: 1px;
                    span {
                        font-size: 11px;
                        .bold__case {
                            font-size: 11px;
                        }
                    }
                }
                @media only print and (max-width: 420px) and (min-width: 350px) {
                    padding: 2px;
                    span {
                        font-size: 12px;
                        .bold__case {
                            font-size: 12px;
                        }
                    }
                }
                @media only print and (max-width: 1123px) and (min-width: 550px) {
                    padding: 2px;
                    span {
                        font-size: 15px;
                        .bold__case {
                            font-size: 15px;
                        }
                    }
                }
           }

           @media print {
               padding: 1px;
           }
        }
        td {
            padding: 3px 0px;
            text-align: center;

            @media print {
                padding: 2px;
            }
        }
    }

    @media print {
        border: 0.5px solid black;
        tr, th, td {
            margin: 0;
            padding: 0;
        }
    }
`;

export const Footer = styled.div`
    margin: 5px 8px;
    
    .footer__note {
        font-size: 18px;
        height: 50px;
    }
    .footer__reExam {
        margin-top: 3px;
        height: 50px;
    }
    .text__note {
        margin-top: 2px;
        font-size: 14px;
    }
    .note__title {
        font-size: 15px;
        font-weight: bold;
    }
    .note__warning {
        margin-top: 15px;
        display: block;
        text-align: center;
        font-size: 12px;
    }
    .footer__staffHandler {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .footer__staffHandler__date {
            font-size: 15px;
            .bold__case {
                font-weight: bold;
            }
        }
        .footer__staffHandler__role {
            font-size: 15px;
        }
        .footer__staffHandler__signal {
            height: 60px;
        }
        .footer__staffHandler__name {
            font-weight: bold;
            font-size: 15px;
        }
    }

    /* @media print {
        .text__note {
            font-size: 11px;
        }
        .footer__note { 
            font-size: 12px;
        }
        .optometrist__name {
            font-size: 13px;
        }
        .doctor__name {
            font-size: 11px;
            text-transform: uppercase;
        }
    } */
    @media only print and (max-width: 298px) and (min-width: 150px) { /* A6 size */
       .note__title {
           font-size: 9px;
       }
       .text__note {
           font-size: 8px;
       }
       .footer__staffHandler {
            .footer__staffHandler__date {
                font-size: 9px;
                .bold__case {
                    font-size: 8px;
                }
            }
            .footer__staffHandler__role {
                font-size: 9px;
            }
            .footer__staffHandler__signal {
                height: 40px;
            }
            .footer__staffHandler__name {
                font-size: 9px;
            }
       }
    }

    @media only print and (max-width: 420px) and (min-width: 350px) { /* A5 size */
        .note__title {
           font-size: 12px;
        }
        .text__note {
           font-size: 11px;
        }
        .footer__staffHandler {
            .footer__staffHandler__date {
                font-size: 11px;
                .bold__case {
                    font-size: 10px;
                }
            }
            .footer__staffHandler__role {
                font-size: 11px;
            }
            .footer__staffHandler__signal {
                height: 60px;
            }
            .footer__staffHandler__name {
                font-size: 11px;
            }
       }
    }
`;